import React, { useEffect } from 'react';
import fbTrack from '../../components/_helpers';

function Youtube(props) {
  useEffect(() => {
    // https://www.youtube.com/watch?v=p5uAOmsaFoM
    if (typeof window !== 'undefined') {
      let pathname = props.params.youtubeRedirect;
      // check if number of characters exceeds the limit
      if (pathname?.length < 15) {
        window.location.href = `https://www.youtube.com/watch?v=${pathname}`
      }
      else {
        window.location.href = 'https://www.youtube.com/truevined'
      }
      fbTrack('trackCustom', 'truevined-youtube-video-visit-redirect');
    }
  }
  , [])
  
  return (
    <div></div>
  )
}

export default Youtube